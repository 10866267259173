import * as React from 'react';
import {useState, useEffect} from 'react';
import {Box, Container, Typography, IconButton, Link} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ServiceCard from "./ServiceCard";
import services from "../../mocks/services";
import ImageScroller from "./ImageScroller";
import {useTranslation} from "react-i18next";

function ServicesHeader() {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                overflow: 'hidden',
                textAlign: 'center',
                color: 'white'
            }}
        >
            <img
                src="/images/car-service.webp"
                alt="services"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    textAlign: 'center',
                    alignItems: 'center',
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: -12,
                    
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: { xs: 'column', sm: 'row' }, // Change to column on small screens, row on larger screens
                    }}
                >
                    <Typography
                        variant="h3"
                        component="h1"
                        gutterBottom
                        sx={{
                            color: 'white',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                            fontSize: {xs: '2rem', sm: '3rem', md: '4rem'},
                            lineHeight: {xs: '2.5rem', sm: '3.5rem', md: '4.5rem'},
                            textAlign: 'center', // Center text when stacked
                        }}
                    >
                        {t('hero.welcome')}
                    </Typography>
                    <Typography
                        variant="h3"
                        component="h1"
                        gutterBottom
                        sx={{
                            color: '#ed382a',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                            fontSize: {xs: '2rem', sm: '3rem', md: '4rem'},
                            lineHeight: {xs: '2.5rem', sm: '3.5rem', md: '4.5rem'},
                            ml: { sm: 1, xs: 0 }, // No margin when stacked vertically
                            textAlign: 'center',  // Center text when stacked
                        }}
                    >
                        {t('hero.shtap')}
                    </Typography>
                </Box>

                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                        color: 'white',
                        maxWidth: '80%',
                        fontSize: {xs: '1rem', sm: '1.1rem', md: '1.2rem'},
                        lineHeight: {xs: '1.2rem', sm: '1.3rem', md: '1.5rem'},
                        display: 'inline-block',
                        padding: ' ',
                        borderRadius: '4px'
                    }}
                >
                    {t('hero.description')}
                </Typography>
                <Link
                    href='/membership'
                    variant='button'
                    sx={{
                        padding: '12px 24px',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        borderRadius: '24px',
                        textDecoration: 'none',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#0e4a7b',
                        },
                        mt: 2,
                        alignSelf: 'center',
                    }}
                >
                    <Typography
                        variant="button"
                        sx={{
                            color: 'white',
                            fontSize: {
                                xs: '0.75rem', // Smaller font size for mobile (xs viewport)
                                sm: '0.875rem', // Slightly larger for small devices (sm viewport)
                                md: '1rem', // Default font size for medium and larger devices
                            },
                        }}
                    >
                        {t('membership.link.title')}
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
}

export default function ServicesSection() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardsToShow, setCardsToShow] = useState(1);
    const {t, i18n} = useTranslation();
    const servicesLocal = i18n.language.includes("en") ? services.en : i18n.language.includes("am") ? services.hy : services.ru;
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                setCardsToShow(3);
            } else if (window.innerWidth >= 768) {
                setCardsToShow(2);
            } else {
                setCardsToShow(1);
            }
        };

        handleResize(); // Set initial state based on the current window size
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? servicesLocal.length - cardsToShow : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === servicesLocal.length - cardsToShow ? 0 : prevIndex + 1));
    };

    const transformValue = `translateX(-${(100 / cardsToShow) * currentIndex}%)`;

    return (
        <>
            <ServicesHeader/>
            <Container maxWidth='lg' sx={{position: 'relative', my: 4}}>
                <Box sx={{display: 'flex', alignItems: 'center', overflow: 'hidden', position: 'relative'}}>
                    <IconButton
                        onClick={handlePrevClick}
                        sx={{mr: 2, zIndex: 1}}
                    >
                        <ArrowBackIosIcon/>
                    </IconButton>
                    <Box sx={{flexGrow: 1, overflow: 'hidden', position: 'relative'}}>
                        <Typography
                            variant='h3'
                            component='h2'
                            sx={{
                                textAlign: 'center', // Center the text
                                mb: 4, // Add some margin to the bottom
                                fontSize: {xs: '1rem', sm: '2rem', md: '4rem'},
                                fontWeight: 'bold'

                            }}
                        >
                            {t('membership.title')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                transition: 'transform 0.5s ease-in-out',
                                transform: transformValue,
                                paddingBottom: '2rem'
                            }}
                        >
                            {servicesLocal.map((service, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        flex: `0 0 ${100 / cardsToShow}%`,
                                        maxWidth: `${100 / cardsToShow}%`,
                                        boxSizing: 'border-box',
                                        paddingRight: index < servicesLocal.length - 1 ? 2 : 0,
                                    }}
                                >
                                    <ServiceCard
                                        title={service.name}
                                        description={service.description}
                                        img={service.img}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <IconButton
                        onClick={handleNextClick}
                        sx={{ml: 2, zIndex: 1}}
                    >
                        <ArrowForwardIosIcon/>
                    </IconButton>
                </Box>
            </Container>
            <ImageScroller/>
        </>
    );
}
