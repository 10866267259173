import React, {useState} from 'react';
import {GoogleLogin} from '@react-oauth/google';
import {Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';
import {jwtDecode} from "jwt-decode";
import {googleLoginUrl} from "../../common/config";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Loading from "../Helpers/Loading";
import {useTranslation} from "react-i18next";

const Login = ({setIsAuthenticated}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const {t} = useTranslation();

    const handleGoogleSuccess = async (response) => {
        setLoading(true);
        const userObject = jwtDecode(response.credential);
        const res = await fetch(googleLoginUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...userObject, token: response.credential})
        });

        if (res.ok) {
            const data = await res.json();
            localStorage.setItem('access', data.access);
            localStorage.setItem('refresh', data.refresh);
            setIsAuthenticated(true);
            navigate('/profile');
        } else if (res.status === 404) {
            // Assuming 404 is the status code when user does not exist
            setOpenDialog(true);
        } else {
            console.error('Failed to authenticate with backend');
        }
        setLoading(false);
    };

    const handleGoogleError = (error) => {
        console.log('Login Failed:', error);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleRegisterRedirect = () => {
        setOpenDialog(false);
        navigate('/register'); // Assuming you have a register route
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <Container maxWidth="md" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '5rem'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    width: '100%',
                    border: '1px solid #d1d9e6',
                }}
            >
                <Typography component="h1" variant="h5" sx={{mb: 3, color: '#333'}}>
                    {t('appBar.manageAccount.login.content.title')}
                </Typography>
                <Typography variant="body2" gutterBottom sx={{mb: 3, color: '#333'}}>
                    {t("appBar.manageAccount.login.content.content")}
                </Typography>
                <GoogleLogin
                    theme='filled_blue'
                    size='large'
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                />
            </Box>

            {/* Dialog for user does not exist */}
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>{t('appBar.manageAccount.loginDialog.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('appBar.manageAccount.loginDialog.content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        {t('appBar.manageAccount.loginDialog.cancel')}
                    </Button>
                    <Button onClick={handleRegisterRedirect} color="primary">
                        {t('appBar.manageAccount.loginDialog.register')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Login;
