import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Container,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    FormControlLabel,
    Link,
    RadioGroup,
    FormControl,
    FormLabel,
    Radio
} from '@mui/material';
import {useTranslation} from "react-i18next";
import useFetch from "../../common/useFetch";
import {paymentIntentsUrl} from "../../common/config";
import {useMembershipPlans} from "../../mocks/plans";
import Loading from "./Loading";

export default function BuyPage() {
    const location = useLocation();
    const membershipPlans = useMembershipPlans();
    const {planName} = location.state || {};
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [makePayment, setMakePayment] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(planName === "STANDARD" ? membershipPlans[0] : planName === "PREMIUM" ? membershipPlans[1] : membershipPlans[2]);
    const [termsAccepted, setTermsAccepted] = useState(false); // State to track if the terms are accepted
    const [paymentMethod, setPaymentMethod] = useState("card"); // State for payment method

    if (selectedPlan.services.en !== undefined) {
        selectedPlan.services = i18n.language.includes("en") ? selectedPlan.services.en : i18n.language.includes("am") ? selectedPlan.services.hy : selectedPlan.services.ru;
    }

    useEffect(() => {
        setSelectedPlan(planName === "STANDARD" ? membershipPlans[0] : planName === "PREMIUM" ? membershipPlans[1] : membershipPlans[2]);
    }, [i18n.language]);

    const price = selectedPlan?.price;
    const services = selectedPlan?.services;
    const choosePlan = planName === "STANDARD" ? 1 : planName === "PREMIUM" ? 2 : 3;

    const {response: paymentData, loading: paymentLoading, error: paymentError} = useFetch(
        makePayment ? paymentIntentsUrl : null,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            body: JSON.stringify({plan_id: choosePlan, payment_method: paymentMethod}), // Add payment method to the request
        },
        [makePayment]
    );
    useEffect(() => {
        setMakePayment(false);
    }, [paymentError]);

    useEffect(() => {
        setMakePayment(false);
        if (paymentData?.redirect_url) {
            console.log(paymentData?.redirect_url);
            if (paymentData?.redirect_url.form_url === undefined) {
                window.location.href = paymentData?.redirect_url
            } else {
                localStorage.setItem('pxNumber', paymentData?.redirect_url.px_number);
                window.location.href = paymentData?.redirect_url.form_url;
            }
        } else {
            // console.error('Failed to get payment URL');
        }
    }, [paymentData, navigate]);

    const handlePayment = () => {
        if (termsAccepted) {
            setMakePayment(true);
        }
    };

    const handleTermsChange = (event) => {
        setTermsAccepted(event.target.checked);
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value); // Update the payment method based on user selection
    };
    if (paymentLoading) {
        return <Loading/>
    }
    return (
        <Container maxWidth="md" sx={{mt: 6}}>
            <Box sx={{
                p: 4,
                boxShadow: 4,
                borderRadius: 3,
                bgcolor: selectedPlan?.backgroundColor || '#f9f9f9', // Use the plan's background color
                textAlign: 'center'
            }}>
                {/* Display the plan icon */}
                {selectedPlan?.icon && (
                    <Box sx={{mb: 2}}>
                        {selectedPlan.icon}
                    </Box>
                )}
                <Typography variant="h4" component="h1" gutterBottom
                            sx={{fontWeight: 'bold', color: selectedPlan?.color || '#1976d2'}}>
                    {t('buyPage.purchase')} {selectedPlan.title || 'Selected Plan'}
                </Typography>
                <Typography variant="h5" gutterBottom sx={{color: '#333', mt: 2}}>
                    {t('sidebar.myPlans.currentPlan.content.price')}: <span
                    style={{fontWeight: 'bold'}}>{price || 'N/A'}</span>
                </Typography>
                <Typography variant="h6" gutterBottom sx={{color: '#555', mt: 4, mb: 2}}>
                    {t('buyPage.servicesIncluded')}:
                </Typography>
                <List sx={{mb: 4}}>
                    {services?.map((service, index) => (
                        <ListItem key={index} sx={{display: 'flex', justifyContent: 'center'}}>
                            <ListItemText
                                primary={<Typography variant="body1" sx={{
                                    fontWeight: 'bold',
                                    color: selectedPlan?.color || '#1976d2'
                                }}>{service.name}</Typography>}
                                secondary={<Typography variant="body2"
                                                       sx={{color: '#757575'}}>{service.description}</Typography>}
                                sx={{textAlign: 'left', maxWidth: '500px'}}
                            />
                        </ListItem>
                    ))}
                </List>

                {/* Payment Method Selection */}
                <FormControl component="fieldset" sx={{mb: 2, width: '100%', pr: {md: 20, xs: 0}, pl: {md: 20, xs: 0}}}>
                    <FormLabel component="legend" sx={{mb: 2, textAlign: 'center', color: "#1976d2"}}>
                        {t('buyPage.selectPaymentMethod')}
                    </FormLabel>
                    <RadioGroup
                        aria-label="payment-method"
                        name="payment-method"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        row
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center', // Aligns radio buttons and images vertically
                        }}
                    >
                        {/* Telcell Section */}
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                value="telcell"
                                control={<Radio/>}
                                label={t('buyPage.paymentMethod.telcell')}
                                sx={{marginBottom: '8px'}}
                            />
                            {/* Telcell Logo */}
                            <img
                                src='/images/Telcell-Wallet_Logo_Main_RGB.png'
                                alt="TELCELL"
                                style={{
                                    width: '120px', // Adjusted to be consistent with the credit card size
                                    height: 'auto',
                                    filter: paymentMethod === 'telcell' ? 'none' : 'grayscale(100%)', // Apply greyscale if not selected
                                    opacity: paymentMethod === 'telcell' ? 1 : 0.5, // Lower opacity if not selected
                                    marginTop: '8px',
                                }}
                            />
                        </Box>

                        {/* Credit Card Section */}
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <FormControlLabel
                                value="card"
                                control={<Radio/>}
                                label={t('buyPage.paymentMethod.creditCard')}
                                sx={{marginBottom: '8px'}}
                            />
                            {/* Credit Card Logo */}
                            <img
                                src='/images/credit-card.svg'
                                alt="Credit Card"
                                style={{
                                    width: '80px', // Adjusted for consistency
                                    height: 'auto',
                                    filter: paymentMethod === 'card' ? 'none' : 'grayscale(100%)', // Apply greyscale if not selected
                                    opacity: paymentMethod === 'card' ? 1 : 0.5, // Lower opacity if not selected
                                    marginTop: '8px',
                                }}
                            />
                        </Box>
                    </RadioGroup>
                </FormControl>


                {/* Terms and Conditions Checkbox */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={handleTermsChange}
                            name="terms"
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            {t('buyPage.acceptTerms')}{' '}
                            <Link href={`${process.env.PUBLIC_URL}/Terms_and_Conditions.pdf`} target="_blank"
                                  rel="noopener noreferrer">
                                {t('buyPage.termsAndConditions')}
                            </Link>
                        </Typography>
                    }
                    sx={{mb: 4}}
                />

                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        py: 2,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        bgcolor: selectedPlan?.buttonColor || '#2656c1', // Use the plan's button color
                        color: '#f85c3a',
                        '&:hover': {
                            bgcolor: selectedPlan?.buttonHoverColor || '#003bb6', // Use the plan's button hover color
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4,
                    }}
                    onClick={handlePayment}
                    disabled={!termsAccepted} // Disable the button if terms are not accepted
                >
                    {t('buyPage.continue')} {/* Updated button text */}
                </Button>
            </Box>
        </Container>
    );
}
