import React from 'react';
import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                py: 5,
                px: 2,
                mt: 'auto',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                marginTop: '2rem',
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="space-between">
                    {/* Follow Us Section */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.followUs.title')}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Link href="https://www.facebook.com/people/SHTAP/61565528515232/" target="_blank" rel="noopener" color="inherit" aria-label="Facebook">
                                <img
                                    src="/icons/facebook.svg"
                                    alt="Shtap Facebook"
                                    style={{ width: '2rem', height: '2rem' }}
                                />
                            </Link>
                            <Link href="https://www.instagram.com/shtap_auto_service/" target="_blank" rel="noopener" color="inherit" aria-label="Instagram">
                                <img
                                    src="/icons/instagram.svg"
                                    alt="Shtap Instagram"
                                    style={{ width: '2rem', height: '2rem' }}
                                />
                            </Link>
                            <Link href="https://www.tiktok.com/@shtap_auto_service" target="_blank" rel="noopener" color="inherit" aria-label="TikTok">
                                <img
                                    src="/icons/tik-tok.svg"
                                    alt="Shtap Tik Tok"
                                    style={{ width: '2rem', height: '2rem' }}
                                />
                            </Link>                        </Box>
                    </Grid>

                    {/*/!* Mobile Applications Section *!/*/}
                    {/*<Grid item xs={12} sm={4}>*/}
                    {/*    <Typography variant="h6" gutterBottom>*/}
                    {/*        {t('footer.mobileApps.title')}*/}
                    {/*    </Typography>*/}
                    {/*    <Box sx={{ display: 'flex', gap: 2 }}>*/}
                    {/*        <Link href="https://apps.apple.com" target="_blank" rel="noopener" color="inherit" aria-label="Download Shtap on the App Store">*/}
                    {/*            <img*/}
                    {/*                src="/icons/app-store.webp"*/}
                    {/*                alt="App Store"*/}
                    {/*                style={{ width: '2rem', height: '2rem' }}*/}
                    {/*            />*/}
                    {/*        </Link>*/}
                    {/*        <Link href="https://play.google.com" target="_blank" rel="noopener" color="inherit" aria-label="Get Shtap on Google Play">*/}
                    {/*            <img*/}
                    {/*                src="/icons/google-play.webp"*/}
                    {/*                alt="Google Play"*/}
                    {/*                style={{ width: '2rem', height: '2rem' }}*/}
                    {/*            />*/}
                    {/*        </Link>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}

                    {/* Contact Us Section */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('navTabs.contactUs.content.title')}
                        </Typography>
                        <Typography variant="body2">
                            {t('navTabs.contactUs.content.phoneTitle')}{' '}
                            <Link href={`tel:8009`} color="inherit">
                                {t('navTabs.contactUs.content.phone')}
                            </Link>
                        </Typography>
                        <Typography variant="body2">
                            {t('navTabs.contactUs.content.emailTitle')}{' '}
                            <Link href={`mailto:${t('navTabs.contactUs.content.email')}`} color="inherit">
                                {t('navTabs.contactUs.content.email')}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
