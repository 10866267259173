import {jwtDecode} from "jwt-decode";
import {googleLoginUrl, loginUrl} from "./config";
import useFetch from "./useFetch";

export const handleGoogleSuccess = async (response) => {
    const userObject = jwtDecode(response.credential);
    const res = await fetch(googleLoginUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({...userObject, token: response.credential})
    });

    if (res.ok) {
        const data = await res.json();
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        return data
    } else {
        console.error('Failed to authenticate with backend');
        return null
    }
};
export const userLogin = async (username, password, action) => {
    const res = await fetch(loginUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, action })
    });

    if (res.ok) {
        const data = await res.json();

        // Save access and refresh tokens
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);

        // Save user data
        localStorage.setItem('user', JSON.stringify(data.user));

        // Check if the user is new
        if (data.newUser) {
            // Handle new user logic if needed, such as showing a welcome message
            console.log('Welcome, new user!');
        }

        return { success: true, ...data };
    } else if (res.status === 404) {
        return { success: false, message: 'User not found' };
    } else if (res.status === 400) {
        const errorData = await res.json();
        return { success: false, message: errorData.error || 'Invalid request' };
    } else {
        console.error('Failed to authenticate with backend');
        return { success: false, message: 'Server error. Please try again later.' };
    }
};
