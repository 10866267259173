import {useTranslation} from "react-i18next";
import StandardIcon from '@mui/icons-material/StarBorder';
import PremiumIcon from '@mui/icons-material/Star';
import ExclusiveIcon from '@mui/icons-material/Stars';

export const useMembershipPlans = () => {
    const {t, i18n} = useTranslation();

    return [
        {
            title: t("membership.plans.standard.title"),
            description: t("membership.plans.standard.description"),
            price: t("membership.plans.standard.price"),
            icon: <StandardIcon sx={{fontSize: 50, color: '#1976d2'}}/>,
            services: {
                "en": membershipServices.en.STANDARD,
                "hy": membershipServices.hy.STANDARD,
                "ru": membershipServices.ru.STANDARD
            },
            planName: 'STANDARD'
        },
        {
            title: t("membership.plans.premium.title"),
            description: t("membership.plans.premium.description"),
            price: t("membership.plans.premium.price"),
            icon: <PremiumIcon sx={{fontSize: 50, color: '#ffb400'}}/>,
            services:{
                "en": membershipServices.en.PREMIUM,
                "hy": membershipServices.hy.PREMIUM,
                "ru": membershipServices.ru.PREMIUM
            },
            planName: 'PREMIUM'
        },
        {
            title: t("membership.plans.exclusive.title"),
            description: t("membership.plans.exclusive.description"),
            price: t("membership.plans.exclusive.price"),
            icon: <ExclusiveIcon sx={{fontSize: 50, color: '#f44336'}}/>,
            services: {
                "en": membershipServices.en.EXCLUSIVE,
                "hy": membershipServices.hy.EXCLUSIVE,
                "ru": membershipServices.ru.EXCLUSIVE
            },
            planName: 'EXCLUSIVE'
        },
    ];
};

export const membershipServices = {
    en: {
        "STANDARD": [
            {
                name: 'Towing',
                description: 'Towing service up to 25 km. An additional fee of 250 AMD is charged for each additional kilometer',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Lockout',
                description: 'Lockout service for opening of locked car doors.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Flat tire',
                description: 'Flat tire service for changing a flat tire with a spare tire.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Battery',
                description: 'Battery commissioning and testing service',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Fuel',
                description: 'Fuel supply up to 5 liters',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Sober driver',
                description: 'Sober driver service for driving your car to a desired location',
                img: '/images/sober-driver.webp'
            }
        ],
        "PREMIUM": [
            {
                name: 'Towing',
                description: 'Towing service up to 50 km. An additional fee of 250 AMD is charged for each additional kilometer',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Lockout',
                description: 'Lockout service for opening of locked car doors.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Flat tire',
                description: 'Flat tire service for changing a flat tire with a spare tire.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Battery',
                description: 'Battery commissioning and testing service',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Fuel',
                description: 'Fuel supply up to 5 liters',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Sober driver',
                description: 'Sober driver service for driving your car to a desired location',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Help my friend",
                description: "The service with the possibility of 4 orders includes the possibility of 4 orders for the Client as well as the opportunity for full access to the service for one additional person.",
                img: "/images/friends.webp"
            }
        ],
        "EXCLUSIVE": [
            {
                name: 'Towing',
                description: 'Towing service up to 100 km. An additional fee of 250 AMD is charged for each additional kilometer',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Lockout',
                description: 'Lockout service for opening of locked car doors.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Flat tire',
                description: 'Flat tire service for changing a flat tire with a spare tire.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Battery',
                description: 'Battery commissioning and testing service',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Fuel',
                description: 'Fuel supply up to 5 liters',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Sober driver',
                description: 'Sober driver service for driving your car to a desired location',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Help my friend",
                description: "The service with the possibility of 5 orders includes the possibility of 5 orders for the Client as well as the opportunity for full access to the service for one additional person.",
                img: "/images/friends.webp"
            },
            {
                name: "BONUS",
                description: "Transfer of the Client or the additional person by a premium class car to \"Zvartnots\" airport or from the airport to any other direction up to 100 km.",
                img: "/images/car-airport.webp"
            }
        ]
    },
    hy: {
        "STANDARD": [
            {
                name: 'Քարշակում',
                description: 'Քաշման ծառայություն մինչև 25 կմ. Ավելացված 250 դրամ հավելավճար յուրաքանչյուր լրացուցիչ կիլոմետրի համար:',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Փակված վիճակում բացում',
                description: 'Փակված մեքենայի դռների բացման ծառայություն:',
                img: '/images/lockout.webp'
            },
            {
                name: 'Ծակ անվադող',
                description: 'Ծակ անվադող փոխելու ծառայություն պահեստային անվադողով:',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Մարտկոց',
                description: 'Մարտկոցի համալրման և փորձարկման ծառայություն:',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Վառելիք',
                description: 'Վառելիքի մատակարարում մինչև 5 լիտր:',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Սթափ վարորդ',
                description: 'Սթափ վարորդի ծառայություն՝ ձեր մեքենան ցանկալի վայր տանելու համար:',
                img: '/images/sober-driver.webp'
            }
        ],
        "PREMIUM": [
            {
                name: 'Քարշակում',
                description: 'Քաշման ծառայություն մինչև 50 կմ. Ավելացված 250 դրամ հավելավճար յուրաքանչյուր լրացուցիչ կիլոմետրի համար:',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Փակված վիճակում բացում',
                description: 'Փակված մեքենայի դռների բացման ծառայություն:',
                img: '/images/lockout.webp'
            },
            {
                name: 'Ծակ անվադող',
                description: 'Ծակ անվադող փոխելու ծառայություն պահեստային անվադողով:',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Մարտկոց',
                description: 'Մարտկոցի համալրման և փորձարկման ծառայություն:',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Վառելիք',
                description: 'Վառելիքի մատակարարում մինչև 5 լիտր:',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Սթափ վարորդ',
                description: 'Սթափ վարորդի ծառայություն՝ ձեր մեքենան ցանկալի վայր տանելու համար:',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Ընկերոջս օգնիր",
                description: "Ծառայությունը 4 պատվերի հնարավորությամբ ներառում է 4 պատվեր հաճախորդի համար և մեկ լրացուցիչ անձի համար ամբողջական ծառայության մատչելիություն:",
                img: "/images/friends.webp"
            }
        ],
        "EXCLUSIVE": [
            {
                name: 'Քարշակում',
                description: 'Քաշման ծառայություն մինչև 100 կմ. Ավելացված 250 դրամ հավելավճար յուրաքանչյուր լրացուցիչ կիլոմետրի համար:',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Փակված վիճակում բացում',
                description: 'Փակված մեքենայի դռների բացման ծառայություն:',
                img: '/images/lockout.webp'
            },
            {
                name: 'Ծակ անվադող',
                description: 'Ծակ անվադող փոխելու ծառայություն պահեստային անվադողով:',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Մարտկոց',
                description: 'Մարտկոցի համալրման և փորձարկման ծառայություն:',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Վառելիք',
                description: 'Վառելիքի մատակարարում մինչև 5 լիտր:',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Սթափ վարորդ',
                description: 'Սթափ վարորդի ծառայություն՝ ձեր մեքենան ցանկալի վայր տանելու համար:',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Ընկերոջս օգնիր",
                description: "Ծառայությունը 5 պատվերի հնարավորությամբ ներառում է 5 պատվեր հաճախորդի համար և մեկ լրացուցիչ անձի համար ամբողջական ծառայության մատչելիություն:",
                img: "/images/friends.webp"
            },
            {
                name: "ԲՈՆՈՒՍ",
                description: "Հաճախորդին կամ լրացուցիչ անձին պրեմիում դասի մեքենայով տեղափոխումը «Զվարթնոց» օդանավակայան կամ օդանավակայանից մինչև 100 կմ այլ ուղղությամբ:",
                img: "/images/car-airport.webp"
            }
        ]
    },
    ru: {
        "STANDARD": [
            {
                name: 'Эвакуатор',
                description: 'Услуга эвакуации до 25 км. Дополнительная плата в размере 250 драм за каждый дополнительный километр.',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Открытие заблокированных дверей',
                description: 'Услуга по открытию заблокированных дверей автомобиля.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Замена колеса',
                description: 'Услуга по замене проколотой шины на запасное колесо.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Аккумулятор',
                description: 'Услуга по проверке и зарядке аккумулятора.',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Топливо',
                description: 'Поставка топлива до 5 литров.',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Трезвый водитель',
                description: 'Услуга трезвого водителя для доставки вашего автомобиля в нужное место.',
                img: '/images/sober-driver.webp'
            }
        ],
        "PREMIUM": [
            {
                name: 'Эвакуатор',
                description: 'Услуга эвакуации до 50 км. Дополнительная плата в размере 250 драм за каждый дополнительный километр.',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Открытие заблокированных дверей',
                description: 'Услуга по открытию заблокированных дверей автомобиля.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Замена колеса',
                description: 'Услуга по замене проколотой шины на запасное колесо.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Аккумулятор',
                description: 'Услуга по проверке и зарядке аккумулятора.',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Топливо',
                description: 'Поставка топлива до 5 литров.',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Трезвый водитель',
                description: 'Услуга трезвого водителя для доставки вашего автомобиля в нужное место.',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Помоги моему другу",
                description: "Услуга с возможностью 4 заказов включает возможность использования для клиента, а также предоставление полной услуги одному дополнительному человеку.",
                img: "/images/friends.webp"
            }
        ],
        "EXCLUSIVE": [
            {
                name: 'Эвакуатор',
                description: 'Услуга эвакуации до 100 км. Дополнительная плата в размере 250 драм за каждый дополнительный километр.',
                img: '/images/tow-truck.webp'
            },
            {
                name: 'Открытие заблокированных дверей',
                description: 'Услуга по открытию заблокированных дверей автомобиля.',
                img: '/images/lockout.webp'
            },
            {
                name: 'Замена колеса',
                description: 'Услуга по замене проколотой шины на запасное колесо.',
                img: '/images/flat-tire.webp'
            },
            {
                name: 'Аккумулятор',
                description: 'Услуга по проверке и зарядке аккумулятора.',
                img: '/images/car-battery.webp'
            },
            {
                name: 'Топливо',
                description: 'Поставка топлива до 5 литров.',
                img: '/images/car-fuel.webp'
            },
            {
                name: 'Трезвый водитель',
                description: 'Услуга трезвого водителя для доставки вашего автомобиля в нужное место.',
                img: '/images/sober-driver.webp'
            },
            {
                name: "Помоги моему другу",
                description: "Услуга с возможностью 5 заказов включает возможность использования для клиента, а также предоставление полной услуги одному дополнительному человеку.",
                img: "/images/friends.webp"
            },
            {
                name: "БОНУС",
                description: "Трансфер клиента или дополнительного человека на автомобиле премиум-класса в аэропорт \"Звартноц\" или из аэропорта в любое другое направление на расстояние до 100 км.",
                img: "/images/car-airport.webp"
            }
        ]
    }
}

export default membershipServices;
