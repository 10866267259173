import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const images = [
    '/images/scroller/image1_hd.webp',
    '/images/scroller/image2_hd.webp',
    '/images/scroller/image3_hd.webp',
    '/images/scroller/image4_hd.webp',
    '/images/scroller/image5_hd.webp',
];

export default function ImageScroller() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imagesToShow, setImagesToShow] = useState(3);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                setImagesToShow(3);
            } else if (window.innerWidth >= 768) {
                setImagesToShow(2);
            } else {
                setImagesToShow(1);
            }
        };

        handleResize(); // Set initial state based on the current window size
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - imagesToShow ? 0 : prevIndex + 1
            );
        }, 4000); // Change image every 3 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [imagesToShow]);

    const transformValue = `translateX(-${(100 / imagesToShow) * currentIndex}%)`;

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                mt: 4,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    transition: 'transform 1s ease-in-out',
                    transform: transformValue,
                }}
            >
                {images.map((image, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={image}
                        alt={`Slide ${index}`}
                        sx={{
                            flex: `0 0 ${100 / imagesToShow}%`,
                            maxWidth: `${100 / imagesToShow}%`,
                            height: '300px', // Adjust the height as needed
                            objectFit: 'cover',
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
}
