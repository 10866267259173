const services = {
    en: [
        {
            name: 'Towing',
            description: 'Towing service up to 100 km. An additional fee of 250 AMD is charged for each additional kilometer',
            img: '/images/tow-truck.webp'
        },
        {
            name: 'Lockout',
            description: 'Lockout service for opening of locked car doors.',
            img: '/images/lockout.webp'
        },
        {
            name: 'Flat tire',
            description: 'Flat tire service for changing a flat tire with a spare tire.',
            img: '/images/flat-tire.webp'
        },
        {
            name: 'Battery',
            description: 'Battery commissioning and testing service',
            img: '/images/car-battery.webp'
        },
        {
            name: 'Fuel',
            description: 'Fuel supply up to 5 liters',
            img: '/images/car-fuel.webp'
        },
        {
            name: 'Sober driver',
            description: 'Sober driver service for driving your car to a desired location',
            img: '/images/sober-driver.webp'
        }
    ],
    hy: [
        {
            name: 'Քարշակում',
            description: 'Քաշման ծառայություն մինչև 100 կմ. Ավելացված 250 դրամ հավելավճար յուրաքանչյուր լրացուցիչ կիլոմետրի համար:',
            img: '/images/tow-truck.webp'
        },
        {
            name: 'Փակված վիճակում բացում',
            description: 'Փակված մեքենայի դռների բացման ծառայություն:',
            img: '/images/lockout.webp'
        },
        {
            name: 'Ծակ անվադող',
            description: 'Հարթ անվադող փոխելու ծառայություն:',
            img: '/images/flat-tire.webp'
        },
        {
            name: 'Մարտկոց',
            description: 'Մարտկոցի համալրման և փորձարկման ծառայություն:',
            img: '/images/car-battery.webp'
        },
        {
            name: 'Վառելիք',
            description: 'Վառելիքի մատակարարում մինչև 5 լիտր:',
            img: '/images/car-fuel.webp'
        },
        {
            name: 'Սթափ վարորդ',
            description: 'Սթափ վարորդի ծառայություն՝ ձեր մեքենան ցանկալի վայր տանելու համար:',
            img: '/images/sober-driver.webp'
        }
    ],
    ru: [
        {
            name: 'Эвакуатор',
            description: 'Услуга эвакуации до 100 км. Дополнительная плата в размере 250 драм за каждый дополнительный километр.',
            img: '/images/tow-truck.webp'
        },
        {
            name: 'Открытие заблокированных дверей',
            description: 'Услуга по открытию заблокированных дверей автомобиля.',
            img: '/images/lockout.webp'
        },
        {
            name: 'Замена колеса',
            description: 'Услуга по замене проколотой шины на запасное колесо.',
            img: '/images/flat-tire.webp'
        },
        {
            name: 'Аккумулятор',
            description: 'Услуга по проверке и зарядке аккумулятора.',
            img: '/images/car-battery.webp'
        },
        {
            name: 'Топливо',
            description: 'Поставка топлива до 5 литров.',
            img: '/images/car-fuel.webp'
        },
        {
            name: 'Трезвый водитель',
            description: 'Услуга трезвого водителя для доставки вашего автомобиля в нужное место.',
            img: '/images/sober-driver.webp'
        }
    ]
}

export default services;
