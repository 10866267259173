import { Container, Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

export default function OurMission() {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', minHeight: '100vh', bgcolor: '#f5f5f5' }}>
            {/* Left Side: Image */}
            <Box
                sx={{
                    flex: 1,
                    m: '4rem',
                    mr: '2rem',
                    backgroundImage: 'url(/images/mission.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    display: { xs: 'none', md: 'block' }, // Hide on small screens, show on medium and up
                }}
                aria-label="Mission Image"
            />

            {/* Right Side: Content */}
            <Container maxWidth="lg" sx={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ my: 4, p: 3, bgcolor: '#fff', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
                    <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        {t('navTabs.mission.content.title')}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#555', lineHeight: 1.8, whiteSpace: 'pre-line' }}>
                        {t("navTabs.mission.content.content")}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
