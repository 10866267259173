import './App.css';
import React, {useEffect, useTransition} from "react";
import ServicesSection from "./components/Services/Services";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import ServiceRequests from "./components/Sidebar/ServiceRequests";
import ButtonAppBar from "./components/Helpers/AppBar";
import NavTabs from "./components/Tabs/Tabs";
import Registration from "./components/Authentication/Registration";
import Profile from "./components/Sidebar/Profile";
import Help from "./components/Sidebar/Help";
import Login from "./components/Authentication/Login";
import Footer from "./components/Helpers/Footer";
import About from "./components/Tabs/About";
import Contact from "./components/Tabs/Contact";
import MyPlan from "./components/Sidebar/MyPlan";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Membership from "./components/Tabs/Membership";
import BuyPage from "./components/Helpers/BuyPage";
import OurMission from "./components/Tabs/OurMission";
import PaymentStatus from "./components/Helpers/PaymentStatus";
import CurrentPlan from "./components/Sidebar/CurrentPlan";
import RedirectPage from "./components/Helpers/RedirectPage";

export const clientId = process.env.REACT_APP_GOOGLE_CLIENT_KEY

function App() {
    const [tab, setTab] = React.useState('home');
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [t, i18n] = useTransition();

    useEffect(() => {
        if (localStorage.getItem('access') !== null) {
            setIsAuthenticated(true);
        }
    }, [isAuthenticated]);

    function handleHomeClick() {
        setTab('home');
    }

    function handleManageAccountClick(action) {
        setTab(action)
    }

    function handleSidebarOpen(value) {
        setSidebarOpen(value)
    }

    return (
            <BrowserRouter>
                {/*Sidebar*/}
                <Sidebar sidebarState={sidebarOpen}
                         setSidebarState={handleSidebarOpen}
                         isAuthenticated={isAuthenticated}/>

                {/*App Bar*/}
                <ButtonAppBar handleSidebarOpen={handleSidebarOpen}
                              handleHomeClick={handleHomeClick}
                              handleManageAccountClick={handleManageAccountClick}
                              isAuthenticated={isAuthenticated}
                              setIsAuthenticated={setIsAuthenticated}
                />

                <Routes>

                    <Route path="/" element={
                        <>
                            <ServicesSection/>
                        </>
                    }/>
                
                    <Route path="/about" element={
                        <>
                            <About/>
                        </>
                    }/>
                    <Route path="/contact" element={
                        <>
                            <Contact/>
                        </>
                    }/>
                    <Route path="/mission" element={
                        <>
                            <OurMission/>
                        </>
                    }/>
                    <Route path="/login" element={
                        (<GoogleOAuthProvider clientId={clientId}>
                            <Login setIsAuthenticated={setIsAuthenticated}/>
                        </GoogleOAuthProvider>)}
                    />
                    <Route path="/register" element={
                        (<GoogleOAuthProvider clientId={clientId}>
                            <Registration setIsAuthenticated={setIsAuthenticated}/>
                        </GoogleOAuthProvider>)}
                    />
                    <Route path="/membership" element={<Membership/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/buy" element={<BuyPage/>}/>
                    <Route path="/payment/:status" element={<PaymentStatus />} />
                    <Route path="/currentPlan" element={<CurrentPlan/>}/>
                    <Route path="/payment/callback" element={<RedirectPage/>}/>
                </Routes>
                {/*Footer*/}
                <Footer/>
            </BrowserRouter>
    );

}

export default App;


