import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box} from '@mui/material';

const MessageDialog = ({open, handleClose, success, message, successTitle='Success', failureTitle='Failed' }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={{color: success ? 'green' : 'red'}}>
                    <Typography
                        variant='h5'>{success ? successTitle : failureTitle}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
