import React, {useEffect, useState} from 'react';
import {Typography, Box, TextField, Container, Button, Alert, MenuItem} from '@mui/material';
import {usersUrl} from "../../common/config";
import Loading from "../Helpers/Loading";
import useUserProfile from "../../common/useUserProfile";
import useFetch from "../../common/useFetch";
import {useTranslation} from "react-i18next";
import MessageDialog from "../Helpers/MessageDialog";
import {useLocation, useNavigate} from "react-router-dom";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const Profile = () => {
    const {userProfile, error, loading: profileLoading} = useUserProfile(usersUrl);
    const {t} = useTranslation();
    const navigator = useNavigate();
    const location = useLocation();

    // Form Fields State
    const [phone, setPhone] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [gender, setGender] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [name, setName] = useState(userProfile?.name || '');

    // Validation States
    const [validPhone, setValidPhone] = useState(false);
    const [validDateOfBirth, setValidDateOfBirth] = useState(false);
    const [validLicenseNumber, setValidLicenseNumber] = useState(false);
    const [validPassportNumber, setValidPassportNumber] = useState(false);

    const [updateProfile, setUpdateProfile] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(null);
    const [showLicenseAlert, setShowLicenseAlert] = useState(false);

    // Individual Field Error Visibility States
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [showDateOfBirthError, setShowDateOfBirthError] = useState(false);
    const [showLicenseNumberError, setShowLicenseNumberError] = useState(false);
    const [showPassportNumberError, setShowPassportNumberError] = useState(false);

    const {
        response: updateResponse,
        error: updateError,
        loading: updateLoading
    } = useFetch(updateProfile ? usersUrl : null, {
        method: 'PUT',
        body: JSON.stringify({
            phone,
            dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null,
            street,
            city,
            country,
            licenseNumber,
            passportNumber,
            gender,
            name
        }),
    }, [updateProfile]);

    useEffect(() => {
        if (updateResponse) {
            navigator('/buy', {state: location.state});
        }
    }, [updateResponse]);

    // Field Validation Logic
    const validatePhone = (value) => {
        const phoneRegex = /^\+?\d{7,}$/;
        setValidPhone(phoneRegex.test(value));
    };

    const validateDateOfBirth = (value) => {
        console.log(value);
        if (value) {
            const age = dayjs().diff(value, 'year');
            setValidDateOfBirth(age >= 18 && age <= 100); // Age should be between 18 and 100
        } else {
            setValidDateOfBirth(false); // Ensure validation fails if dateOfBirth is empty
        }
    };

    const validateLicenseNumber = (value) => {
        setValidLicenseNumber(value.length >= 5); // License number should have at least 5 characters
    };

    const validatePassportNumber = (value) => {
        setValidPassportNumber(value.length >= 5); // Passport number cannot be empty
    };


    // Populate User Profile Data on Load
    useEffect(() => {
        if (userProfile) {
            localStorage.setItem('profilePicture', userProfile?.picture || '');
            setPhone(userProfile?.phone || '');
            setDateOfBirth(userProfile?.date_of_birth ? dayjs(userProfile?.date_of_birth).isValid() ? dayjs(userProfile?.date_of_birth) : null : null);
            setStreet(userProfile?.street || '');
            setCity(userProfile?.city || '');
            setCountry(userProfile?.country || '');
            setLicenseNumber(userProfile?.license_number || '');  // Ensure a fallback value for license_number
            setPassportNumber(userProfile?.passport_number || '');
            setShowLicenseAlert(!userProfile?.license_number);
            setGender(userProfile?.gender || '');
            setName(userProfile?.name || '');

            // Perform initial validations but do not show validation errors
            validatePhone(userProfile?.phone || '');
            validateDateOfBirth(userProfile?.date_of_birth ? dayjs(userProfile?.date_of_birth).format('YYYY-MM-DD') : null);
            validateLicenseNumber(userProfile?.license_number || '');
            validatePassportNumber(userProfile?.passport_number || '');
        }
    }, [userProfile]);

    // Handle Form Submission
    const handleUpdateProfile = () => {
        if (!validLicenseNumber) {
            setDialogMessage(t('sidebar.profile.content.dialog.licenseNumberRequired'));
            setDialogOpen(true);
            setSuccess(false);
            return;
        }
        setUpdateProfile(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        window.location.reload();
    };

    // Disable the button unless all validations are passed
    const isFormValid = validPhone && validDateOfBirth && validLicenseNumber && validPassportNumber && gender !== '';
    
    if (profileLoading || (updateLoading && updateProfile) || userProfile === undefined || userProfile === null) return <Loading/>;
    if (error) return <Loading message={error.message}/>;
    if (updateError) return <Loading message={updateError.message}/>;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container maxWidth="xl">
                <Box sx={{mt: 4, p: 3, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper'}}>
                    {showLicenseAlert && (
                        <Alert severity="info" sx={{mb: 3, p: 3}}>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                {t('sidebar.profile.content.licenseNumberAlert')}
                            </Typography>
                            <Typography variant="body1" sx={{mt: 2, color: '#e92112'}}>
                                <strong>{t('sidebar.profile.content.licenseNumberAlertNote')}:</strong> {t('sidebar.profile.content.licenseNumberAlertContent')}
                            </Typography>
                        </Alert>
                    )}

                    <Typography variant="h4" component="h1" gutterBottom>
                        {t('sidebar.profile.content.title')}
                    </Typography>

                    {/* Hidden ID Field */}
                    <TextField
                        label={t('sidebar.profile.content.fieldNames.id_number')}
                        value={userProfile?.id_number}
                        fullWidth
                        margin="normal"
                        InputProps={{readOnly: true}}
                        sx={{display: 'none'}} // Hide the field
                    />

                    {/* Read-Only Fields */}
                    <TextField
                        label={t('sidebar.profile.content.fieldNames.name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{shrink: name !== ''}}
                    />

                    <TextField
                        label={t('sidebar.profile.content.fieldNames.username')}
                        value={userProfile?.user.username}
                        fullWidth
                        margin="normal"
                        InputProps={{readOnly: true}} // Non-editable
                        InputLabelProps={{shrink: true}}

                    />

                    <TextField
                        label={t('sidebar.profile.content.fieldNames.phone')}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}  // Only update value here
                        onBlur={() => {
                            validatePhone(phone);
                            setShowPhoneError(true);
                        }}  // Trigger validation on blur
                        fullWidth
                        margin="normal"
                        required
                        error={showPhoneError && !validPhone}
                        helperText={showPhoneError && !validPhone ? t('sidebar.profile.content.phoneError') : ''}
                    />

                    <DatePicker
                        label={t('sidebar.profile.content.fieldNames.dateOfBirth')}
                        value={dateOfBirth ? dayjs(dateOfBirth) : null}  // Ensure it's a Day.js object or null
                        onChange={(date) => {
                            setDateOfBirth(date);
                            validateDateOfBirth(date);  // Validate the selected date
                        }}
                        onBlur={() => {
                            validateDateOfBirth(dateOfBirth);  // Trigger validation on blur
                            setShowDateOfBirthError(true);     // Show validation error
                        }}
                        sx={{ mt: 2, width: '100%' }} 
                        maxDate={dayjs().subtract(18, 'year')}  
                        minDate={dayjs().subtract(100, 'year')}  
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={showDateOfBirthError && !validDateOfBirth}  // Show error only if the field is invalid and has been blurred
                                helperText={showDateOfBirthError && !validDateOfBirth ? t('sidebar.profile.content.ageError') : ''}
                                margin="normal"
                                fullWidth
                                required={true}
                            />
                        )}
                    />


                    <TextField
                        select
                        label={gender ? t('sidebar.profile.content.fieldNames.gender') : t('sidebar.profile.content.fieldNames.chooseGender')}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        fullWidth
                        margin="normal"
                        required={true}
                    >
                        <MenuItem value="male">{t('sidebar.profile.content.fieldNames.male')}</MenuItem>
                        <MenuItem value="female">{t('sidebar.profile.content.fieldNames.female')}</MenuItem>
                        <MenuItem value="other">{t('sidebar.profile.content.fieldNames.other')}</MenuItem>
                    </TextField>

                    {/* New Address Fields */}
                    <TextField
                        label={t('sidebar.profile.content.fieldNames.street')}
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label={t('sidebar.profile.content.fieldNames.city')}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    
                    <TextField
                        label={t('sidebar.profile.content.fieldNames.country')}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        fullWidth
                        margin="normal"
                    />

                    <TextField
                        label={t('sidebar.profile.content.fieldNames.licenseNumber')}
                        value={licenseNumber}
                        onChange={(e) => setLicenseNumber(e.target.value)}  // Only update value here
                        onBlur={() => {
                            validateLicenseNumber(licenseNumber);
                            setShowLicenseNumberError(true);
                        }}  // Trigger validation on blur
                        fullWidth
                        margin="normal"
                        required={true}
                        error={showLicenseNumberError && !validLicenseNumber}
                        helperText={showLicenseNumberError && !validLicenseNumber ? t('sidebar.profile.content.licenseNumberError') : ''}
                        InputProps={{
                            readOnly: !!userProfile?.license_number,  // Make read-only if the license number is already set
                        }}
                    />

                    <TextField
                        label={t('sidebar.profile.content.fieldNames.passportNumber')}
                        value={passportNumber}
                        onChange={(e) => setPassportNumber(e.target.value)}  // Only update value here
                        onBlur={() => {
                            validatePassportNumber(passportNumber);
                            setShowPassportNumberError(true);
                        }}  // Trigger validation on blur
                        fullWidth
                        margin="normal"
                        required
                        error={showPassportNumberError && !validPassportNumber}
                        helperText={showPassportNumberError && !validPassportNumber ? t('sidebar.profile.content.passportNumberError') : ''}
                    InputProps={{
                        readOnly: !!userProfile?.passport_number,  
                    }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{mt: 2}}
                        onClick={handleUpdateProfile}
                        disabled={!isFormValid}
                    >
                        {t('sidebar.profile.content.next')}
                    </Button>

                    <MessageDialog
                        open={dialogOpen}
                        handleClose={handleCloseDialog}
                        success={success}
                        successTitle={t('sidebar.profile.content.dialog.success.title')}
                        failureTitle={t('sidebar.profile.content.dialog.failure.title')}
                        message={dialogMessage}
                    />
                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default Profile;
