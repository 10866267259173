import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    Button,
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import {useTranslation} from "react-i18next";

const PaymentStatus = () => {
    const {status} = useParams();  // Capture the "status" parameter from the URL
    const navigate = useNavigate();
    const {t} = useTranslation();
    const handleGoToProfile = () => {
        navigate('/currentPlan');  // Redirect to the profile page
    };

    const handleGoToMainMenu = () => {
        navigate('/');  // Redirect to the main menu (homepage)
    };

    const handleGetBack = () => {
        navigate(-3);  // Go back to the previous page
    };

    const renderDialogContent = () => {
        switch (status) {
            case 'success':
                return (
                    <>
                        <DialogTitle color='green'>{t('payment.success.title')}</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{mb: 2}}>
                                {t('payment.success.content')}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleGoToMainMenu} color="secondary">
                                {t('payment.goToMainMenu')}
                            </Button>
                            <Button onClick={handleGoToProfile} color="primary">
                                {t('payment.goToProfile')}
                            </Button>
                        </DialogActions>
                    </>
                );
            case 'cancelled':
            case 'failure':
                return (
                    <>
                        <DialogTitle>{status === 'cancelled' ? t('payment.cancel.title') : t('payment.failure.title')}</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{mb: 2}}>
                                {status === 'cancelled'
                                    ? t('payment.cancel.content')
                                    : t('payment.failure.content')}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleGoToMainMenu} color="secondary">
                                {t('payment.goToMainMenu')}
                            </Button>
                            <Button onClick={handleGetBack} color="primary">
                                {t('payment.goBack')}
                            </Button>
                        </DialogActions>
                    </>
                );
            default:
                return <Typography variant="body1">Invalid payment status.</Typography>;
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'relative'
        }}>
            {/* Background Loading Circle */}
            <CircularProgress sx={{position: 'absolute', zIndex: 0}}/>

            {/* Dialog */}
            <Dialog open={true} maxWidth="xs" fullWidth>
                {renderDialogContent()}
            </Dialog>
        </Box>
    );
};

export default PaymentStatus;
