import React from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';

const Loading = ({message}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress/>
            {message && <Typography variant="h6" sx={{mt: 2}}>
                {message}
            </Typography>}
        </Box>
    );
};

export default Loading;
