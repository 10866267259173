import React from 'react';
import {
    Container,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, styled,
    Typography, useTheme
} from '@mui/material';
import {Person, Build, HelpOutline} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {useTranslation} from "react-i18next";
const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
const Sidebar = ({isAuthenticated, sidebarState, setSidebarState}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} = useTranslation();
    if (!isAuthenticated) {
        return null; // Do not render the sidebar if the user is not authenticated
    }

    return (
        <SwipeableDrawer open={sidebarState} onOpen={() => setSidebarState(true)} onClose={() => setSidebarState(false)}
        >
            <DrawerHeader>
                <IconButton onClick={() => setSidebarState(false)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </DrawerHeader>
            <Container>
                <Typography variant="h6" sx={{paddingTop: 0}}>
                    {t('sidebar.title')}
                </Typography>
                <List>
                    <ListItem button onClick={() => {
                        navigate('/profile');
                        setSidebarState(false)
                    }}>
                        <ListItemIcon>
                            <Person/>
                        </ListItemIcon>
                        <ListItemText primary={t("sidebar.profile.title")}/>
                    </ListItem>
                    <ListItem button onClick={() => {
                        navigate('/payment');
                        setSidebarState(false)
                    }}>
                        <ListItemIcon>
                            <ShoppingCartIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("sidebar.myPlans.title")}/>
                    </ListItem>
                    <ListItem button onClick={() => {
                        navigate('/service-requests');
                        setSidebarState(false)
                    }}>
                        <ListItemIcon>
                            <Build/>
                        </ListItemIcon>
                        <ListItemText primary={t("sidebar.serviceRequests.title")}/>
                    </ListItem>
                    <ListItem button onClick={() => {
                        navigate('/help');
                        setSidebarState(false)
                    }}>
                        <ListItemIcon>
                            <HelpOutline/>
                        </ListItemIcon>
                        <ListItemText primary={t("sidebar.help.title")}/>
                    </ListItem>
                </List>
            </Container>
        </SwipeableDrawer>
    );
};

export default Sidebar;
