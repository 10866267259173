import React, {useEffect, useRef, useState} from 'react';
import {GoogleLogin} from '@react-oauth/google';
import {
    Button,
    Container, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material';
import {jwtDecode} from "jwt-decode";
import {registerUrl} from "../../common/config";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Loading from "../Helpers/Loading";
import {useTranslation} from "react-i18next";

const Registration = ({setIsAuthenticated}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const {t} = useTranslation();

    const handleGoogleSuccess = async (response) => {
        setLoading(true)
        const res = await fetch(registerUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: response.credential
            })
        })
        if (res.ok) {
            const data = await res.json();
            localStorage.setItem('access', data.access)
            localStorage.setItem('refresh', data.refresh)
            setIsAuthenticated(true);
            setLoading(false)
            navigate('/profile')
        } else {
            setLoading(false)
            const errorData = await res.json();
            if (errorData.error === 'Email is already registered') {
                setDialogMessage(t('appBar.manageAccount.registerDialog.content'));
            }
            else {
                setDialogMessage(errorData.error || 'Failed to authenticate with backend');
            }
            setDialogOpen(true);
        }
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleGoogleError = (error) => {
        console.log('Login Failed:', error);
    };
    if (loading) {
        return <Loading/>;
    }
    return (
        <Container maxWidth="md" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '5rem'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    width: '100%',
                    border: '1px solid #d1d9e6',
                }}
            >
                <Typography component="h1" variant="h5" sx={{mb: 3}}>
                    {t('appBar.manageAccount.register.content.title')}
                </Typography>
                <Typography variant="body2" gutterBottom sx={{mb: 3, color: '#333'}}>
                    {t("appBar.manageAccount.register.content.content")}
                </Typography>
                <GoogleLogin text='signup_with'
                             theme='filled_blue'
                             size='large'
                             onSuccess={handleGoogleSuccess}
                             onError={handleGoogleError}

                />
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    {t('appBar.manageAccount.registerDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        {t('appBar.manageAccount.registerDialog.cancel')}
                    </Button>
                    <Button onClick={() => navigate('/login')} color="primary" autoFocus>
                        {t('appBar.manageAccount.registerDialog.login')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
export default Registration;
