import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import useFetch from "../../common/useFetch";
import {userPlansUrl} from "../../common/config";
import {useTranslation} from "react-i18next";
import ExclusiveIcon from "@mui/icons-material/Stars";
import PremiumIcon from "@mui/icons-material/Star";
import StandardIcon from "@mui/icons-material/StarBorder";
import {membershipServices} from '../../mocks/plans';
import Loading from "../Helpers/Loading";
import {useLocation, useNavigate} from "react-router-dom";

const CurrentPlan = () => {
    const {t, i18n} = useTranslation();
    const [userPlanData, setUserPlan] = useState(null);
    const {response, loading, error} = useFetch(userPlansUrl, {method: 'GET'}, []);
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    useEffect(() => {
        if (response && response.data && response?.data[0]) setUserPlan(response?.data[0]);
    }, [response, loading, error]);

    if (loading) return <Loading/>;
    if (error) return <div>{t("common.errorFetchingData")}</div>;
    if (userPlanData === null) return <div>{t("common.noPlanData")}</div>;

    const getPlanIcon = (planName) => {
        switch (planName) {
            case 'STANDARD':
                return <StandardIcon sx={{fontSize: 50, color: '#1976d2'}}/>;
            case 'PREMIUM':
                return <PremiumIcon sx={{fontSize: 50, color: '#ffb400'}}/>;
            case 'EXCLUSIVE':
                return <ExclusiveIcon sx={{fontSize: 50, color: '#f44336'}}/>;
            default:
                return null;
        }
    };

    const renderServices = (planName) => {
        const servicesLocal = i18n.language.includes("en")
            ? membershipServices.en[planName]
            : i18n.language.includes("am")
                ? membershipServices.hy[planName]
                : membershipServices.ru[planName];

        return servicesLocal?.map((service, index) => (
            <Box key={index} sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                <img src={service.img} alt={service.name} style={{width: 50, height: 50, marginRight: 16}}/>
                <Box>
                    <Typography variant="h6" component="h3">
                        {service.name}
                    </Typography>
                    <Typography variant="body2">
                        {service.description}
                    </Typography>
                </Box>
            </Box>
        ));
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        p: 3,
                        m: 5,
                        boxShadow: 3,
                        borderRadius: 2,
                        textAlign: 'center',
                        bgcolor:
                            userPlanData.plan.name === 'STANDARD' ? '#f0f4f7' :
                                userPlanData.plan.name === 'PREMIUM' ? '#ffecb3' :
                                    '#ffcdd2'
                    }}
                >
                    {getPlanIcon(userPlanData.plan.name)}

                    <Typography variant="h4" component="h1" gutterBottom>
                        {t("sidebar.myPlans.currentPlan.title")}
                    </Typography>

                    <Box sx={{mt: 4, p: 3, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper'}}>
                        <Typography variant="h6" component="h2" color="primary">
                            {t("sidebar.myPlans.currentPlan.content.plan")}: {userPlanData.plan.name}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.price")}: {Number(userPlanData.plan.price).toFixed(2)} AMD
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.serviceRequests")}: {userPlanData.plan.service_request_count}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.duration")}: {userPlanData.plan.duration} days
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.startDate")}: {new Date(userPlanData.start_date).toLocaleDateString()}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.endDate")}: {new Date(userPlanData.end_date).toLocaleDateString()}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.status")}: {userPlanData.active ? 'Active' : 'Inactive'}
                        </Typography>
                        <Typography variant="h6" component="h2">
                            {t("sidebar.myPlans.currentPlan.content.remainingRequests")}: {userPlanData.service_request_count}
                        </Typography>
                    </Box>

                    <Button
                        sx={{
                            mt: 2,
                            bgcolor: '#64b5f6',
                            color: 'white',
                            '&:hover': {
                                bgcolor: '#42a5f5'
                            }
                        }}
                        onClick={() => navigate('/buy', {state: location.state})}
                    >
                        {t("sidebar.myPlans.currentPlan.content.updatePlan")}
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box sx={{
                    p: 2,
                    boxShadow: 3,
                    m: 5,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {t("membership.title")}
                    </Typography>
                    {renderServices(userPlanData.plan.name)}
                </Box>
            </Grid>
        </Grid>
    );
}

export default CurrentPlan;
