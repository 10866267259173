import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {baseUrl} from "../../common/config";
import Loading from "./Loading";

export default function RedirectPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const data = searchParams.get('data'); // Extract user_id from query params
        const redirectUrl = searchParams.get('redirect_url'); // Extract plan_id from query params

        // Retrieve pxNumber from localStorage
        const storedValue = localStorage.getItem('pxNumber');

        window.location.href = `${baseUrl}/payment/callback/?pxNumber=${storedValue}&data=${data}&redirect_url=${redirectUrl}`;
    }, [navigate]);

    return (
        <div>
            <Loading/>
        </div>
    );
}
