import React, {useState} from 'react';
import {Container, Box, Typography, TextField, Button, Paper, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {send_contact_email} from "../../common/email";

export default function Contact() {
    const {t} = useTranslation();

    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const templateParams = {
            name: form.name,
            email: form.email,
            message: form.message,
        };

        const emailResponse = send_contact_email(templateParams)
        if (emailResponse) {
            setSuccess(true);
            setLoading(false);
            setForm({name: '', email: '', message: ''});
        } else {
            setSuccess(false);
            setLoading(false);
        }
    };

    return (
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, minHeight: '100vh'}}>
            {/* Left Side: Image */}
            <Box
                sx={{
                    flex: 1,
                    m: {xs: '1rem', md: '6rem'},
                    backgroundImage: 'url(/images/contact-us.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    maxWidth: '100%',
                    maxHeight: 'auto',
                    borderRadius: 2,
                    display: {xs: 'none', md: 'block'},
                }}
                aria-label='contact us image'
            />

            {/* Right Side: Form */}
            <Container maxWidth="sm" sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Paper elevation={3} sx={{p: {xs: 2, md: 4}, borderRadius: 3}}>
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#1976d2',
                            mb: 3,
                        }}>
                        {t('navTabs.contactUs.content.title')}
                    </Typography>

                    <Box sx={{mb: 4}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{fontWeight: 'bold', color: '#333'}}>
                                    {t("navTabs.contactUs.content.companyNameTitle")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" sx={{color: 'gray'}}>
                                    {t("navTabs.contactUs.content.companyName")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{fontWeight: 'bold', color: '#333'}}>
                                    {t("navTabs.contactUs.content.addressTitle")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" sx={{color: 'gray'}}>
                                    {t("navTabs.contactUs.content.address")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{fontWeight: 'bold', color: '#333'}}>
                                    {t("navTabs.contactUs.content.phoneTitle")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" sx={{color: 'gray'}}>
                                    {t("navTabs.contactUs.content.phone")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" sx={{fontWeight: 'bold', color: '#333'}}>
                                    {t("navTabs.contactUs.content.emailTitle")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" sx={{color: 'gray'}}>
                                    {t("navTabs.contactUs.content.email")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 2}}>
                        <TextField
                            fullWidth
                            label={t('navTabs.contactUs.content.form.name')}
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            margin="normal"
                            required
                            sx={{
                                '& .MuiInputBase-root': {
                                    borderRadius: '8px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#82B4CB',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label={t('navTabs.contactUs.content.form.email')}
                            name="email"
                            type="email"
                            value={form.email}
                            onChange={handleChange}
                            margin="normal"
                            required
                            sx={{
                                '& .MuiInputBase-root': {
                                    borderRadius: '8px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#82B4CB',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label={t('navTabs.contactUs.content.form.message')}
                            name="message"
                            value={form.message}
                            onChange={handleChange}
                            margin="normal"
                            required
                            multiline
                            rows={4}
                            sx={{
                                '& .MuiInputBase-root': {
                                    borderRadius: '8px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#82B4CB',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#1976d2',
                                    },
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            sx={{
                                mt: 2,
                                py: 1.5,
                                borderRadius: '24px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                backgroundColor: '#1976d2',
                                '&:hover': {
                                    backgroundColor: '#0e4a7b',
                                },
                            }}>
                            {loading ? t('navTabs.contactUs.content.form.sending') : t('navTabs.contactUs.content.form.submit')}
                        </Button>
                    </Box>

                    {success === true && (
                        <Typography variant="body1" color="success.main" sx={{mt: 2}}>
                            {t('navTabs.contactUs.content.successMessage')}
                        </Typography>
                    )}
                    {success === false && (
                        <Typography variant="body1" color="error.main" sx={{mt: 2}}>
                            {t('navTabs.contactUs.content.errorMessage')}
                        </Typography>
                    )}
                </Paper>
            </Container>
        </Box>
    );
}
