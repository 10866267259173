import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Icon, IconButton, Link, Drawer, List, ListItem, ListItemText} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';

export default function ButtonAppBar({
                                         handleHomeClick,
                                         handleManageAccountClick,
                                         isAuthenticated,
                                         setIsAuthenticated,
                                         handleSidebarOpen,
                                     }) {
    const [manageAccountIcon, setManageAccountIcon] = useState(<ManageAccountsIcon/>);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const userProfilePicture = localStorage.getItem('profilePicture');

    useEffect(() => {
        if (userProfilePicture)
            setManageAccountIcon(
                <Icon sx={{
                    width: '2rem',
                    height: '2rem',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    border: '1px solid white',
                }}>
                    <img src={userProfilePicture} alt="profile" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                </Icon>)
        else
            setManageAccountIcon(<ManageAccountsIcon/>)
    }, [userProfilePicture]);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <IconButton
                        color="inherit"
                        sx={{textTransform: 'none', ":hover": {bgcolor: "inherit"}}}
                        component={Link}
                        href="/"
                        onClick={handleHomeClick}
                    >
                        <img src="/logo.ico" alt="icon" style={{height: 40}}/>
                    </IconButton>

                    <Box sx={{display: {xs: 'none', md: 'flex'}, gap: 2}}>
                        <Link
                            href='/membership'
                            color='inherit'
                            sx={{
                                padding: '12px 24px',
                                ml: '2rem',
                                bgcolor: '#ed382a',
                                color: 'white',
                                borderRadius: '24px',
                                fontSize: '1rem',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#91332c',
                                },
                                textAlign: 'center',
                            }}
                        > {t("membership.link.title")}
                        </Link>
                        <Link
                            href='/contact'
                            color='inherit'
                            sx={{
                                padding: '12px 24px',
                                bgcolor: '#4184c7',
                                color: 'white',
                                borderRadius: '24px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#0e4a7b',
                                },
                                textAlign: 'center',
                            }}
                        >
                            {t("navTabs.contactUs.title")}
                        </Link>
                        <Link
                            href='/mission'
                            color='inherit'
                            sx={{
                                padding: '12px 24px',
                                bgcolor: '#4184c7',
                                color: 'white',
                                borderRadius: '24px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#0e4a7b',
                                },
                                textAlign: 'center',
                            }}
                        >
                            {t("navTabs.mission.title")}
                        </Link>
                        <Link
                            href='/about'
                            color='inherit'
                            sx={{
                                padding: '12px 24px',
                                bgcolor: '#4184c7',
                                color: 'white',
                                borderRadius: '24px',
                                textDecoration: 'none',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#0e4a7b',
                                },
                                textAlign: 'center',
                            }}
                        >
                            {t("navTabs.about.title")}
                        </Link>
                    </Box>

                    <Box sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center', gap: 2}}>
                        <IconButton
                            sx={{
                                minWidth: 'auto',
                                color: 'white',
                                ":hover": {transform: 'scale(1.2)'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                i18n.changeLanguage("am");
                            }}
                        >
                            <img
                                src={'/icons/armenia.webp'}
                                style={{
                                    width: 24,
                                    height: 'auto',
                                    margin: 0,
                                    padding: 0,
                                }}
                                alt={'armenian language'}
                            />
                            <Typography
                                variant="button"
                                sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 1
                                }}
                            >
                                AM
                            </Typography>
                        </IconButton>
                        <IconButton
                            sx={{
                                minWidth: 'auto',
                                color: 'white',
                                ":hover": {transform: 'scale(1.2)'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                i18n.changeLanguage("en");
                            }}
                        >
                            <img
                                src={'/icons/united-states.webp'}
                                style={{
                                    width: 24,
                                    height: 'auto',
                                    margin: 0,
                                    padding: 0,
                                }}
                                alt={'english language'}
                            />
                            <Typography
                                variant="button"
                                sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 1
                                }}
                            >
                                EN
                            </Typography>
                        </IconButton>
                        <IconButton
                            sx={{
                                minWidth: 'auto',
                                color: 'white',
                                ":hover": {transform: 'scale(1.2)'},
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                i18n.changeLanguage("ru");
                            }}
                        >
                            <img
                                src={'/icons/russia.webp'}
                                style={{
                                    width: 24,
                                    height: 'auto',
                                    margin: 0,
                                    padding: 0,
                                }}
                                alt={'russian language'}
                            />
                            <Typography
                                variant="button"
                                sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 0,
                                    lineHeight: 1
                                }}
                            >
                                RU
                            </Typography>
                        </IconButton>
                    </Box>

                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            color="inherit"
                            onClick={toggleDrawer(true)}
                            sx={{":hover": {transform: 'scale(1.2)'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer
                            anchor='right'
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <List>
                                <ListItem button component="a" href='/membership' sx={{
                                    padding: '12px 24px',
                                    bgcolor: '#ed382a',
                                    color: 'white',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0e4a7b',
                                    },
                                    textAlign: 'center',
                                }}>
                                    <ListItemText primary={t("membership.link.title")}/>
                                </ListItem>
                                <ListItem button component="a" href='/contact' sx={{
                                    padding: '12px 24px',
                                    bgcolor: '#4184c7',
                                    color: 'white',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0e4a7b',
                                    },
                                    textAlign: 'center',
                                }}>
                                    <ListItemText primary={t("navTabs.contactUs.title")}/>
                                </ListItem>
                                <ListItem button component="a" href='/mission' sx={{
                                    padding: '12px 24px',
                                    bgcolor: '#4184c7',
                                    color: 'white',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0e4a7b',
                                    },
                                    textAlign: 'center',
                                }}>
                                    <ListItemText primary={t("navTabs.mission.title")}/>
                                </ListItem>
                                <ListItem button component="a" href='/about' sx={{
                                    padding: '12px 24px',
                                    bgcolor: '#4184c7',
                                    color: 'white',
                                    textDecoration: 'none',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#0e4a7b',
                                    },
                                    textAlign: 'center',
                                }}>
                                    <ListItemText primary={t("navTabs.about.title")}/>
                                </ListItem>
                                <ListItem sx={{
                                    padding: '12px 24px',
                                    bgcolor: '#4184c7',
                                    color: 'white',
                                    textDecoration: 'none',
                                    textAlign: 'center',
                                }}>
                                    <Box sx={{display: 'flex', gap: 1}}>
                                        <IconButton
                                            sx={{
                                                minWidth: 'auto',
                                                ":hover": {transform: 'scale(1.2)'},
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                i18n.changeLanguage("am");
                                            }}
                                        >
                                            <img
                                                src={'/icons/armenia.webp'}
                                                style={{
                                                    width: 24,
                                                    height: 'auto',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                alt={'armenian language'}
                                            />
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    margin: 0,
                                                    padding: 0,
                                                    lineHeight: 1
                                                }}
                                            >
                                                AM
                                            </Typography>
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                minWidth: 'auto',
                                                ":hover": {transform: 'scale(1.2)'},
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                i18n.changeLanguage("en");
                                            }}
                                        >
                                            <img
                                                src={'/icons/united-states.webp'}
                                                style={{
                                                    width: 24,
                                                    height: 'auto',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                alt={'english language'}
                                            />
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    margin: 0,
                                                    padding: 0,
                                                    lineHeight: 1
                                                }}
                                            >
                                                EN
                                            </Typography>
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                minWidth: 'auto',
                                                ":hover": {transform: 'scale(1.2)'},
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                i18n.changeLanguage("ru");
                                            }}
                                        >
                                            <img
                                                src={'/icons/russia.webp'}
                                                style={{
                                                    width: 24,
                                                    height: 'auto',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                alt={'russian language'}
                                            />
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    margin: 0,
                                                    padding: 0,
                                                    lineHeight: 1
                                                }}
                                            >
                                                RU
                                            </Typography>
                                        </IconButton>
                                    </Box>
                                </ListItem>
                            </List>
                        </Drawer>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
