import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ru from "./locale/ru.json";
import en from "./locale/en.json";
import am from "./locale/am.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, 
        },
        resources: {
            en: {
                translation: en,
            },
            am: {
                translation: am,
            },
            ru: {
                translation: ru,
            },
        },
    });

export default i18n;