import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import './Services.css';

const ServiceCard = ({ title, description, img }) => {
    return (
        <Card
            className="service-card"
            sx={{
                maxWidth: 300,
                height: 450,
                m: 1,
                p: 3,
                borderRadius: '16px',
                backgroundColor: '#c1dbff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <CardMedia
                component="img"
                height="auto"
                alt={title}
                image={img}
                sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ mb: 1, fontWeight: 'bold' }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: '0.875rem' }}
                >
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ServiceCard;
