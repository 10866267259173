import { useState, useEffect } from 'react';
import useFetch from './useFetch'; 
const useUserProfile = (url) => {
    const {response, loading, error} = useFetch(url, {
        method: 'GET',
    }, []);
    
    const [userProfile, setUserProfile] = useState();
    useEffect(() => {
        if (response) {
            setUserProfile(response[0]);
        }
    }, [response]);

    return { userProfile, loading, error };
};

export default useUserProfile;
