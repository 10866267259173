import emailjs from "emailjs-com";

export const emailServiceId = 'service_042bgxl';
export const contactEmailTemplateId = 'template_kyvuuec';
export const registrationEmailTemplateId = 'template_a1m1u1b';
export const emailUserId = '4wxZPlfM6wKzUw7iO';

export function send_email(templateParams, serviceId, templateId, userId): boolean {
    emailjs.send(serviceId, templateId, templateParams, userId)
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            return true;
        })
        .catch((error) => {
            console.error('FAILED...', error);
            return false;
        });
    return true;
}

export function send_contact_email(templateParams): boolean {
    
    return send_email(templateParams, emailServiceId, contactEmailTemplateId, emailUserId)
}

export function send_registration_email(templateParams): boolean {

    return send_email(templateParams, emailServiceId, registrationEmailTemplateId, emailUserId);
}